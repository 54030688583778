@mixin breakpoint($point) {
    @if $point==sm {
        @media (min-width: 576px) {
            @content;
        }
    } @else if $point==max-xsm {
        @media (max-width: 425px) {
            @content;
        }
    } @else if $point==md {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $point==max-md {
        @media (max-width: 767px) {
            @content;
        }
    } @else if $point==lg {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $point==max-lg {
        @media (max-width: 991px) {
            @content;
        }
    } @else if $point==xl {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $point==max-xl {
        @media (max-width: 1199px) {
            @content;
        }
    } @else if $point==xxl {
        @media (min-width: 1400px) {
            @content;
        }
    } @else if $point==max-xxl {
        @media (max-width: 1399px) {
            @content;
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
:root {
    --title: #2d3340;
    --title-2: #171b26;
    --dark: #000000;
    --title-rgb: 0, 0, 0;
    --text: #585c67;
    --body: #ffffff;
    --section: #20212c;
    --dark-2: #2e3443;
    --dark-3: #404656;
    --white: #ffffff;
    --base: #0e76bc;
    --base-2: #f7941d;
    --redish: #f563a9;
    --body-fonts: 'Inter', sans-serif;
    --heading-fonts: 'Inter', sans-serif;
    --border: #ebebeb;
}

body {
    background: var(--body);
    font-family: var(--body-fonts);
    color: var(--text);
    font-size: 16px;
    line-height: 27.5px;
    font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--title);
    font-family: var(--heading-fonts);
    font-weight: 700;
}

h1 {
    font-size: 42px;
}
h2 {
    font-size: 34px;
}
h3 {
    font-size: 28px;
}
h4 {
    font-size: 24px;
}
h5 {
    font-size: 20px;
}
h6 {
    font-size: 18px;
}
@include breakpoint(max-md) {
    h1 {
        font-size: 34px;
    }
    h2 {
        font-size: 28px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 22px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }
}
@include breakpoint(max-sm) {
    h1 {
        font-size: 28px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }
}
ul {
    padding: 0;
    margin: 0;
    li {
        list-style: none;
    }
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a {
    text-decoration: none;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
}
.font-light {
    font-weight: 300;
}
.font-regular {
    font-weight: 400;
}
.font-medium {
    font-weight: 500;
}
.font-semibold {
    font-weight: 600;
}
.font-bold {
    font-weight: 700;
}
.font-extra-bold {
    font-weight: 800;
}
.font-black {
    font-weight: 900;
}
.container {
    position: relative;
    z-index: 1;
}
@include breakpoint(xl) {
    .container {
        max-width: 1290px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
.bg-section {
    background: var(--section) !important;
}
.bg-body {
    background: var(--body) !important;
}
header {
    border-bottom: 1px solid var(--border);
    padding-block: 24px;
    position: sticky;
    top: 0;
    z-index: 99;
    background: var(--body);
    @include breakpoint(max-md) {
        padding-block: 16px;
    }
    .container {
        @include breakpoint(xl) {
            max-width: 1200px;
        }
    }
}
.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .phone-contact {
        display: flex;
        align-items: center;
        gap: 12px;

        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: var(--base-2);
        .number {
            color: var(--base);
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            line-height: 24px;
            margin-top: 4px;
        }
    }
    .logo {
        display: block;
        max-width: 156px;
        img {
            max-width: 100%;
        }
    }
    @include breakpoint(max-sm) {
        .logo {
            max-width: 140px;
        }
        .phone-contact {
            font-size: 8.93635px;
            line-height: 11px;
            svg {
                width: 56px;
            }
            .number {
                font-size: 11.1704px;
                line-height: 13px;
                margin-top: 2.23px;
            }
        }
    }
}
.banner-top {
    padding: 50px 0;
    background: rgba(207, 225, 255, 0.1);
    .container {
        @include breakpoint(xl) {
            max-width: 1200px;
        }
    }
    .title {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 25px;
        color: var(--dark);
    }
    .subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        margin: 0;
        color: #171b26;
    }
    @include breakpoint(mx-sm) {
        padding: 25px 0 62px;
        .title {
            font-size: 28px;
            line-height: 37px;
            margin-bottom: 15px;
        }
        .subtitle {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }
}
.text-base {
    color: var(--base);
}
.reviews-card {
    background: #ffffff;
    /* Black/100 */

    border: 1px solid #f7f7f7;
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 9px;
    }
    .subtitle {
        margin-bottom: 16px;
    }
    padding: 30px 33px;
    @include breakpoint(max-sm) {
        padding: 20px;
    }
    .text-base {
        cursor: pointer;
    }
    @include breakpoint(max-xxl) {
        @include breakpoint(lg) {
            padding: 20px;
            @include breakpoint(max-xl) {
                font-size: 14px;
                line-height: 1.6;
            }
        }
    }
}
.slide-wrapper {
    display: flex;
    flex-direction: row-reverse;
    gap: 30px;
    .main-img {
        width: 0;
        flex-grow: 1;
        overflow: hidden;
        border-radius: 12.4545px;
        img {
            width: 100%;
            height: 475px;
            object-fit: cover;
            transition: all ease 0.3s;
            -webkit-transition: all ease 0.3s;
            -moz-transition: all ease 0.3s;
        }
        &:hover {
            img {
                transform: scale(1.04);
            }
        }
    }
    .slide-images {
        width: 108px;
        padding-right: 4px;
        .inner {
            gap: 16px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            overflow-y: auto;
            gap: 18px;
            @include breakpoint(md) {
                gap: 20px;
            }
        }
        max-height: 475px;
        overflow-y: auto;
        border-radius: 10px;

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(#000, 0.3);
            width: 2px;
            height: 3px;
        }

        &::-webkit-scrollbar {
            width: 2px;
            height: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--base);
            width: 2px;
            height: 3px;
        }
        @include breakpoint(max-md) {
            .inner {
                max-height: 370px;
            }
        }
        @include breakpoint(max-sm) {
            overflow: hidden;
            overflow-x: scroll;
            padding-bottom: 5px;
            .inner {
                height: unset;
                .img {
                    img {
                        width: 19vw;
                    }
                }
            }
        }
        img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 8.62576px;
        }
        .img {
            position: relative;
            border-radius: 8.62576px;
            cursor: pointer;
            &::before {
                inset: 0;
                position: absolute;
                content: '';
                border-radius: 8.62576px;
                background: rgb(#000, 0.6);
                transition: all ease 0.3s;
                -webkit-transition: all ease 0.3s;
                -moz-transition: all ease 0.3s;
            }
            &.active {
                img {
                    border: 2px solid #cbd329;
                }
                &:before {
                    opacity: 0;
                }
            }
        }
    }
    @include breakpoint(max-md) {
        .main-img {
            img {
                height: 370px;
            }
        }
        .slide-images {
            width: 78px;
        }
    }
    @include breakpoint(max-sm) {
        .main-img {
            width: 100%;
            img {
                height: unset;
                width: 100%;
            }
        }
        flex-wrap: wrap;
        .slide-images {
            width: 100%;
            .inner {
                flex-direction: row;
            }
        }
    }
}
.banner-bottom {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    padding-block: 42px;
    @include breakpoint(max-sm) {
        padding-block: 30px;
    }
}
.__accordion-item {
    background: transparent !important;
    border: none;
    // border-bottom: 1px solid var(--border) !important;
    border-radius: 0 !important;
    font-family: var(--heading-fonts);
    // color: var(--white);
    padding-inline: 0;
    &.active {
        // padding: 25px 30px;
        box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.12);
        border-radius: 12px !important;
    }
    .accordion-header {
        border-radius: 0 !important;
        .accordion-button {
            padding-inline: 30px;
            padding-top: 15px;
            padding-bottom: 5px;
            // height: 60px;
            box-shadow: none;
            background: transparent !important;
            border-radius: 0 !important;
            font-size: 20px;
            line-height: 26px;
            color: #585c67;
            font-weight: 400;
            transition: all ease 0.3s;
            -webkit-transition: all ease 0.3s;
            -moz-transition: all ease 0.3s;

            svg {
                // margin-right: 10px;
                color: #585c67;
            }
            &::after {
                display: none;
            }
            .serial {
                font-weight: 300;
                font-size: 30px;
                line-height: 36px;
                margin-right: 15px;
                img {
                    max-width: 29px;
                }
                @include breakpoint(max-sm) {
                    font-size: 24px;
                    margin-right: 12px;
                    img {
                        max-width: 29px;
                    }
                }
            }
            @include breakpoint(max-sm) {
                font-size: 20px;
                line-height: 26px;
                padding-inline: 15px;
            }
            &:not(.collapsed) {
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                color: #2d3340;
                .serial {
                    font-size: 24px;
                    font-weight: 600;
                }
                @include breakpoint(max-sm) {
                    font-size: 20px;
                    line-height: 32px;
                    font-weight: 500;
                }
            }
        }
    }
    .accordion-body {
        padding-inline: 82px 30px;
        padding-top: 10px;
        padding-bottom: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #9b9b9b;
        max-width: 85%;
        @include breakpoint(max-sm) {
            font-size: 16px;
            line-height: 22px;
        }
        @include breakpoint(max-sm) {
            padding-left: 45px;
        }
    }
    @include breakpoint(max-xl) {
        font-size: 16px;
        .accordion-header {
            .accordion-button {
                font-size: 20px;
            }
        }
    }
}
.service-info-section {
    padding-bottom: 110px;
    @include breakpoint(max-md) {
        padding-bottom: 80px;
    }
}
.frequent-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    padding-block: 15px;
    max-width: 716px;
    border-bottom: 1px solid var(--border);
    border-top: 1px solid var(--border);
    @include breakpoint(max-sm) {
        padding-top: 30px;
        padding-bottom: 20px;
    }
}

.left-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
}
input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        display: none;
    }
}
.left-icon ~ .form-control {
    padding-left: 44px;
}
.form-control {
    height: 48px;
    box-shadow: none !important;
    outline: none !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.service-info-card {
    background: #ffffff;
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    .form-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 8px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        display: block;
        color: var(--title-2);
    }
    .item {
        &:not(:last-child) {
            margin-bottom: 32px;
        }
        .gap-4 {
            @include breakpoint(max-xl) {
                gap: 20px !important;
            }
        }
    }
    padding: 16px 27px 17px 48px;
    @include breakpoint(max-sm) {
        padding: 25px 20px;
    }
}
.side-content {
    padding-left: 8px;
    @include breakpoint(max-lg) {
        margin-top: 35px;
    }
    .name {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 9px;
    }
    .subname {
        margin-bottom: 9px;
    }
    p {
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        margin-top: 9px;
    }
    .price {
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 7px;
    }
    .price-total {
        margin-top: 38px;
    }
    .social {
        margin: 11px 0 15px;
    }
    .codice {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }
}
.social {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    a {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: var(--white);
        background: #26529c;
        border-radius: 100px;
        padding-block: 13px;
        padding-inline: 10px;
        flex-grow: 1;
        justify-content: center;
        border: 1px solid transparent;
        &:nth-child(2) {
            background: #60d76a;
        }
        &:nth-child(3) {
            background: #585c67;
        }
        &:hover {
            filter: brightness(0.7);
        }
        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
    }
    @include breakpoint(max-sm) {
        a {
            width: 100%;
        }
    }
}
.section-title {
    text-align: center;
    .title {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 12px;
    }
    .text {
        margin-bottom: 24px;
    }
    margin-bottom: 40px;
}
.invia-per-msg {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 44px;
    display: flex;
    align-items: center;
    gap: 12px;
    @include breakpoint(max-sm) {
        margin-bottom: 30px;
    }
}
.faq-wrap {
    padding-bottom: 60px;
    .accordion {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
.faq-qck-txt {
    border-bottom: 1px solid var(--border);
    border-top: 1px solid var(--border);
    padding-block: 25px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 84px;
    position: relative;
    @include breakpoint(max-sm) {
        margin-bottom: 45px;
    }
}
.color-base {
    .accordion {
        .accordion-header {
            .accordion-button {
                color: var(--base);
                svg {
                    color: var(--base);
                }
            }
        }
    }
}
.faqs-section {
    padding-bottom: 127px;
    @include breakpoint(max-sm) {
        padding-bottom: 45px;
    }
}
.fadeInUp {
    animation: fadeInUp 1.3s;
    -webkit-animation: fadeInUp 1.3s;
    -moz-animation: fadeInUp 1.3s;
    animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(25px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.fadeInLeft {
    animation: fadeInLeft 1.3s;
    -webkit-animation: fadeInLeft 1.3s;
    -moz-animation: fadeInLeft 1.3s;
    animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
}
@keyframes fadeInLeft {
    from {
        opacity: 0.5;
        transform: translateX(25px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.fadeInRight {
    animation: fadeInRight 1.3s;
    -webkit-animation: fadeInRight 1.3s;
    -moz-animation: fadeInRight 1.3s;
    animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
}
@keyframes fadeInRight {
    from {
        opacity: 0.5;
        transform: translateX(-25px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.map {
    width: 100%;
    height: 500px;
    border: none;
}
.map-content {
    position: absolute;
    bottom: 0;
    right: calc(50% - 500px);
    z-index: 2;
    background: var(--white);
    padding: 48px 40px;
    box-shadow: 0px -10px 24px rgba(16, 24, 40, 0.08);
    border-radius: 12px 12px 0px 0px;
    width: 100%;
    max-width: 370px;
    color: var(--dark);
    h5 {
        margin-bottom: 24px;
    }
    p {
        max-width: 248px;
        margin-bottom: 56px;
    }
    @include breakpoint(max-xl) {
        right: calc(50% - 450px);
    }
    @include breakpoint(max-lg) {
        right: calc(50% - 350px);
        padding: 35px;
        p {
            margin-bottom: 30px;
        }
    }
    @include breakpoint(max-md) {
        right: 15px;
        max-width: 235px;
        padding: 25px 23px;
        h5 {
            margin-bottom: 12px;
        }
    }
}
.btn-base {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 31px;
    border-radius: 8px;
    background: var(--base);
    color: var(--white);
    border: 1px solid var(--base);
    display: inline-block;
    &:hover {
        background: transparent;
        color: var(--base);
    }
}
footer {
    background: var(--title-2);
    color: #d2d3d6;
    .container {
        @include breakpoint(xl) {
            max-width: 1200px;
        }
    }
}
.footer-top {
    padding: 100px 0;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    @include breakpoint(max-lg) {
        padding: 60px 0;
    }
    .footer-widget {
        &:first-child {
            max-width: 269px;
        }
        &:nth-child(2) {
            max-width: 316px;
        }
        &:nth-child(3) {
            max-width: 196px;
        }
    }
    @include breakpoint(max-md) {
        flex-wrap: wrap;
        .footer-widget {
            width: 100%;
        }
    }
}
.footer-widget-link {
    color: var(--base-2);
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    padding: 8px 16px;
    border: 1px solid #f7941d;
    border-radius: 24px;
    margin-block: 16px;
    display: inline-block;
    &:hover {
        color: var(--body);
        background: var(--base-2);
    }
}
.footer-bottom {
    font-size: 14px;
    line-height: 20px;
    padding-block: 40px;
    border-top: 1px solid #2d3340;
    .copyright-txt {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
    }
    text-align: center;
    @include breakpoint(max-sm) {
        padding-bottom: 160px;
    }
}
.text-base-2 {
    color: var(--base-2);
}

.preloader {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    background: var(--white);
    transition: all ease 0.7s;
    -webkit-transition: all ease 0.7s;
    -moz-transition: all ease 0.7s;

    &.loaded {
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
    }
}

.preloader > .preloader-box {
    position: absolute;
    width: 345px;
    height: 30px;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -150px;
    -webkit-perspective: 200px;
}

.preloader .preloader-box > div {
    position: relative;
    width: 30px;
    height: 30px;
    background: #ccc;
    float: left;
    text-align: center;
    line-height: 30px;
    font-family: Verdana;
    font-size: 20px;
    color: #fff;
}

.preloader .preloader-box > div:nth-child(1) {
    background: var(--base);
    margin-right: 15px;
    -webkit-animation: movement 600ms ease 0ms infinite alternate;
}
.preloader .preloader-box > div:nth-child(2) {
    background: var(--base);
    margin-right: 15px;
    -webkit-animation: movement 600ms ease 75ms infinite alternate;
}
.preloader .preloader-box > div:nth-child(3) {
    background: var(--base);
    margin-right: 15px;
    -webkit-animation: movement 600ms ease 150ms infinite alternate;
}

.preloader .preloader-box > div:nth-child(4) {
    background: var(--base);
    margin-right: 15px;
    -webkit-animation: movement 600ms ease 225ms infinite alternate;
}

.preloader .preloader-box > div:nth-child(5) {
    background: var(--base);
    margin-right: 15px;
    -webkit-animation: movement 600ms ease 300ms infinite alternate;
}

.preloader .preloader-box > div:nth-child(6) {
    background: var(--base);
    margin-right: 15px;
    -webkit-animation: movement 600ms ease 375ms infinite alternate;
}

.preloader .preloader-box > div:nth-child(7) {
    background: var(--base);
    margin-right: 15px;
    -webkit-animation: movement 600ms ease 450ms infinite alternate;
}

.preloader .preloader-box > div:nth-child(8) {
    background: var(--base);
    -webkit-animation: movement 600ms ease 525ms infinite alternate;
}

@keyframes movement {
    from {
        -webkit-transform: scale(1) translateY(0px) rotateX(0deg);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    to {
        -webkit-transform: scale(1.5) translateY(-25px) rotateX(45deg);
        box-shadow: 0 25px 40px rgba(0, 0, 0, 0.4);
        background: #3399ff;
    }
}
.mobile-popup {
    position: fixed;
    bottom: 150px;
    left: 0;
    transition: all ease 1.2s;
    -webkit-transition: all ease 1.2s;
    -moz-transition: all ease 1.2s;

    z-index: 999;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    transform: translateY(calc(100% + 9px));
    -webkit-transform: translateY(calc(100% + 9px));
    -moz-transform: translateY(calc(100% + 9px));
    &.active {
        transform: translateY(44px);
        -webkit-transform: translateY(44px);
        -moz-transform: translateY(44px);
    }
    &-title {
        font-weight: 600;
        font-size: 10px;
        line-height: 17px;
        color: #26529c;
        padding-top: 5px;
        &::after {
            background: #26529c;
            border-radius: 13px;
            content: '';
            display: block;
            height: 4px;
            width: 64px;
            margin: 0 auto;
            margin-top: 4px;
        }
    }
}
.mobile-popup-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px 15px 14px;
    background: var(--white);
    z-index: 9999;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    .price {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #26529c;
        margin: 0;
        @media (max-width: 360px) {
            font-size: 22px;
        }
    }
    .subtitle {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: #26529c;
        @media (max-width: 360px) {
            font-size: 10px;
        }
    }
    .social {
        justify-content: center;
        &.icons-only {
            gap: 8px;
            a {
                width: 0;
                flex-grow: 1;
                max-width: 67px;
                height: 39px;
                span {
                    display: none;
                }
                @media (max-width: 360px) {
                    padding-block: 8px;
                }
            }
            // flex-direction: row-reverse;
        }
    }
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;

    &.active {
        padding-top: 16px;
    }
}
.mobile-popup-middle {
    padding-inline: 15px;
    // transform: translateY(100%);
    .item {
        padding-top: 12px;
        .title {
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            margin-bottom: 2px;
        }
        .form-control {
            height: 29px;
        }
        .form-label {
            font-weight: 500;
            font-size: 8px;
            line-height: 1.4;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #171b26;
            margin-bottom: 10px;
            display: block;
        }
    }
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;

    hr {
        margin: 0;
        margin-top: 20px;
        border-color: #dbdbdb;
    }
    &:not(.active) {
        padding-block: 0;
    }
}
.__offcanvas {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}
.gap-10px {
    gap: 10px;
}
.target-id {
    position: absolute;
    top: -90px;
}

.errorContainer {
    font-family: var(--body-fonts);
    display: flex;
    justify-content: center;
    min-width: 100vw;
    padding: 2rem;
}

.errorWrapper {
    display: flex;
    max-width: 746px;

    @include breakpoint(max-md) {
        max-width: 420px !important;
    }
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logoContainer {
        img {
            width: 10rem;
        }
    }
    .error_text_container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7rem;
        @include breakpoint(max-md) {
            margin-top: 3rem;
            img {
                max-width: 5rem;
            }
            .errorFour {
                font-size: 6rem !important;
            }
        }
        img {
        }
        .errorFour {
            color: #25aae0;
            font-size: 9rem;
        }
    }

    .error_text_section {
        text-align: center;
        margin-top: 3rem;
        max-width: 500px;

        @include breakpoint(max-md) {
            .errorDescription {
                font-size: 16px !important;
                margin: 1rem;
            }
        }

        .errorDescription {
            font-size: 18px;
            font-weight: 500;
            font-weight: semibold;
            color: #171b26;
            @include breakpoint(max-xsm) {
                font-size: 16px !important;
                line-height: 1.3;
            }
        }
    }
}

@include breakpoint(max-md) {
    .error_buttons_container {
        margin-top: 1rem !important;
    }
}

.error_buttons_container {
    margin-top: 3rem;
    display: flex;

    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @include breakpoint(max-md) {
        button {
            max-width: 8rem;
            padding: 0.7rem !important;
            img {
                max-height: 10px;
            }
            span {
                font-size: 1rem;
            }
        }
    }
    @include breakpoint(max-sm) {
        button {
            max-width: 10rem;
            img {
                height: 15px;
            }
            span {
                font-size: 0.9rem;
            }
        }
    }
    @include breakpoint(max-xsm) {
        button {
            max-width: 6.5rem;
            padding: 0.4rem !important;
            img {
                max-height: 12px !important;
            }
            span {
                font-size: 0.8rem;
            }
        }
    }

    button {
        display: flex;
        img {
            height: 25px;
        }

        outline: none;
        border: none;
        border-radius: 2rem;
        width: 14rem;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }
    span {
        color: #fff;
        font-size: 1.3rem;
        margin-left: 6px;
    }
}

.slide-wrapper {
    @include breakpoint(max-sm) {
        .main-img.fadeInLeft {
            animation: fadeIn2 0.3s !important;
            -webkit-animation: fadeIn2 0.3s !important;
            -moz-animation: fadeIn2 0.3s !important;
        }
        .main-img.fadeInRight {
            animation: fadeIn 0.3s !important;
            -webkit-animation: fadeIn 0.3s !important;
            -moz-animation: fadeIn 0.3s !important;
        }
    }
}
.mobile-change.fadeInLeft {
    animation: fadeInUp 1.2s !important;
    -webkit-animation: fadeInUp 1.2s !important;
    -moz-animation: fadeInUp 1.2s !important;
}
@keyframes fadeIn {
    from {
        opacity: 0.6;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn2 {
    from {
        opacity: 0.62;
    }
    to {
        opacity: 1;
    }
}
